import React from "react"
/* import Seo from "../components/Seo" */

const NotFoundPage = () => {
  /* const seo = {
    "meta": [
      {
        "property": "og:image",
        "content": "/static/4b6bb20aceec244ce998d985501f6b75/Official_logo.svg"
      }
    ],
    "title": "Page not found",
    "description": "ZTrikes."
  } */
  return (
    <div className="container py-24 text-center">
      {/* <Seo {...seo} /> */}
      <div>Logo</div>
      <div>Page not found</div>
    </div>
  )
}

export default NotFoundPage

export const Head = () => <title>Page not found</title>